body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* header */

header {
  display: flex;
  justify-content: space-between;
  /* flex-direction: row-reverse; */
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 40px;
  min-height: 40px;
  align-items: center;
}
header .header__logo {
  color: black;
  font-weight: 600;
  font-size: 18px;
}

header a {
  margin: 0px 10px;
  text-decoration: none;
  color: gray;
}

header a:focus,
header a:hover {
  color: black;
}

/* footer */
footer {
  background-color: #f2f2f2;
  min-height: 40px;
  padding: 20px 40px;
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

footer a {
  text-decoration: none;
  color: gray;
}

footer a:focus,
footer a:hover {
  color: black;
}

.footer__theme-btn {
  cursor: pointer;
  width: 18px;
  height: 18px;
}

/* Post navigation */
.post__navigation {
  display: flex;
  gap: 12px;
  margin: 0 auto;
  max-width: 680px;
  font-size: 16px;
  color: gray;
  cursor: pointer;
  padding: 48px 20px 0px 20px;
}

.post__navigation--active {
  color: black;
  font-weight: 600;
}

/* Post List, post Detail */
.post__list,
.post__detail {
  min-height: 90vh;
  padding: 20px;
  text-align: center;
  max-width: 680px;
  margin: 0 auto;
  text-align: left;
  line-height: 24px;
}

.post__category {
  color: gray;
  border: 1px solid lightgray;
  background: #f2f2f2; 
  padding: 0px 4px;
  border-radius: 10px;
  font-size: 12px;
}

.post__box {
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid #f2f2f2;
}

.post__profile-box {
  display: flex;
  gap: 8px;
  font-size: 14px;
  align-items: center;
}

.post__profile {
  width:36px;
  height: 36px;
  background: #f2f2f2;
  border-radius: 50%;
}

.post__date,
.post__author-name {
  color: gray;
}

.post__title {
  font-size: 20px;
  font-weight: 600;
  margin: 14px 0px;
}

.post__text {
  color: dimgray;
  font-size: 16px;
}

.post__text--pre-wrap {
 white-space: pre-wrap; 
}

.post__list a {
  text-decoration: none;
  color: black;
}

.post__utils-box {
  display: flex;
  gap: 8px;
  flex-direction: row-reverse;
  font-size: 14px;
  color: gray;
}

.post__delete {
    cursor: pointer;  
}

.post__delete:hover, 
.post__delete:focus {
  color: black;
}

.post__edit:hover,
.post__edit:focus,
.post__edit a:hover,
.post__edit a:focus
 {
  color: black;
}

.post__edit a {
  text-decoration: none;
  color: gray;
}

.post__no-post {
  padding: 24px;
  text-align: center;
  color: gray;
  border: 1px solid #f2f2f2;
  border-radius: 20px;
}

.post__detail .post__title {
  font-size: 36px;
  line-height: 40px;
}

.post__detail .post__utils-box {
  padding: 10px 0px;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  flex-direction: row;
}

.post__detail .post__profile-box {
  padding: 10px 0px;
}

.post__detail .post__text {
  padding: 20px 0px;
}

/*profile box*/
.profile__box {
  display: flex;
  gap: 18px;
  align-items: center;
  font-size: 18px;
  margin: 0 auto;
  max-width: 680px;
  text-align: left;
  line-height: 24px;
  justify-content: space-between;
  padding: 20px;
}

.flex__box-lg {
  display: flex;
  gap: 18px;
  align-items: center;
}

.profile__image {
  width: 72px;
  height: 72px;
  background-color: #f2f2f2;
  border-radius: 50%;
}

.profile__name {
  font-size: 16px;
  padding-top: 4px;
}

.profile__email {
  font-weight: 500;
}

.profile__logout {
  color: gray;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
}

.profile__logout:hover, 
.profile__logout:focus {
  color: black;
}

/* carousel */
.carousel {
  margin: 0 auto;
  max-width: 980px;
  margin-top: 36px;
}

ul.carousel__slides {
  display: block;
  position: relative;
  height: 400px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style: none;
}

.carousel__slides {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

ul.carousel__slides input {
  display: none;
}

.carousel__slide-container {
  display: block;
}

.carousel__slide-img {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
  transition: all 0.7s ease-in-out;
}

.carousel__slide-img img {
  width: auto;
  min-width: 100%;
  height: 100%;
}

.carousel__controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  font-size: 100px;
  line-height: 400px;
  color: #fff;
}

.carousel__controls label {
  display: none;
  position: absolute;
  padding: 0 20px;
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;
}

.carousel__slide-img:hover + .carousel__controls label {
  opacity: 0.5;
}

.carousel__controls label:hover {
  opacity: 1;
}

.carousel__controls .carousel__slide-prev {
  width: 49px;
  text-align: left;
  left: 0;
}

.carousel__controls .carousel__slide-next {
  width: 49px;
  text-align: right;
  left: 0;
}

.carousel__dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  z-index: 999;
  text-align: center;
}

.carousel__dots .carousel__dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.5;
  margin: 10px;
}

input:checked + .carousel__slide-container .carousel__slide-img {
  opacity: 1;
  transform: scale(1);
  transition: opacity 1s ease-in-out;
}

input:checked + .carousel__slide-container .carousel__controls label {
  display: block;
}

input#img-1:checked ~ .carousel__dots label#img-dot-1,
input#img-2:checked ~ .carousel__dots label#img-dot-2,
input#img-3:checked ~ .carousel__dots label#img-dot-3 {
  opacity: 1;
}

input:checked + .carousel__slide-container .nav label {
  display: block;
}

/* from */
.form {
  margin:0 auto;
  max-width: 680px;
  padding: 20px;
  margin-top: 20px;
}

.form input {
  height: 20px;
  padding: 10px 10px;
  font-size: 16px;
  border-radius: 0.3rem;
  border: 1px solid lightgray;
  width: 96%;
  max-width: 680px;
}

.form textarea {
  min-height: 400px;
  padding: 10px 10px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 0.3rem;
  border: 1px solid lightgray;
  width: 96%;
  max-width: 680px;
}

.form .form__block {
  margin-top: 20px;
  width: 100%;
}

.form label {
  display: block;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 20px;
}

.form .form__btn--submit {
  width: 100%;
  height: 48px;
  padding: 10px 10px;
  float: right;
  cursor: pointer;
  margin: 0 auto;
  font-size: 16px;
  background-color: #253eb2;
  color: white;
}

.form .form__btn--submit:hover,
.form .form__btn--submit:focus {
  background-color: #1945a4;
}

.form--lg {
  min-height: 70vh;
  margin-top: 10vh;
}

.form__title {
  text-align: center;
  margin-bottom: 4px;
}

.form__link {
  margin-left:10px;
  text-decoration: none;
  color:gray;
}

.form__link:hover,
.form__link:focus {
  color: black;
} 

.form__error {
  color: red;
}

.form select {
  border: 1px solid #f2f2f2;
  max-width: 680px;
  height: 40px;
  padding: 0px 20px;
  border-radius: 0.3rem;
}

/* loader */
.loader {
  width: 48px;
  height: 48px; 
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 5px solid #2563eb;
  border-radius: 50%;
  z-index: 9999;
  animation: rotation is linear infinite;
}

.comments {
  width: 100%;
  padding: 10px;
  font-size: 16px;
}

.comments__form label {
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
  margin-top: 20%;
}

.comments__form textarea {
  min-height: 100px;
  padding: 10px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 0.3 rem;
  border: 1px solid lightgray;
  display: block;
  width: 100%;
  max-width: 680px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.comments__form .form__block {
  margin-top: 10px;
  width: 100%;
  height: 100%;
}

.form__block-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.comments__form .form__btn-submit {
    width: 100px; 
    height: 36px;
    cursor: pointer;
    font-weight: 500;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 5px;
}

.comments__form .form__btn-submit:hover, .comments__form .form__btn-submit:focus {
  background-color: #1945a4;
} 

.comments__list {
  margin-top: 40px;
}

.comment__box {
  padding: 12px 0px;
  border-bottom: 1px solid #f2f2f2;
}

.comment__profile-box {
  display: flex; 
  gap: 10px;
  align-items: center;
  font-size: 14px;
}

.comment__email {
  font-weight: 500;
}

.comment__date {
  color: gray;
}

.comment__delete {
  color: gray;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
}

.comment__delete:hover,
.comment__delete:focus {
  color: black;
}

.comment__text {
  font-size: 14px;
  padding-top: 4px;
}

.dark .form__block {
  color: gray;
}



/* dark mode */

.white {
  transition: all 0.25s linear;
  background-color: white;
}

.dark {
  transition: all 0.25s linear;
  background-color: #1e2937;
  min-height: 100vh;
}

.dark a,
.dark .form label,
.dark .post__delete,
.dark .profile__logout,
.dark .form__title,
.dark .post__navigation--active,
.dark .post__title,
.dark .post__author-name,
.dark .footer__theme-btn,
.dark .profile__name {
  color: white;

}

.dark a:hover, 
.dark a:focus,
.dark .post__text, 
.dark .post__text--pre-wrap, 
.dark .profile__email,
.dark .post__date, 
.dark .form__block {
  color: gray;
}

.dark .form__error {
  color: #ff6a71; 
}

.dark footer, 
.dark header {
  background-color: #111827;
}
